import { API_ROUTES } from '@constants/api';
import { api } from '@utils/api';
import { createContext, useEffect, useState } from 'react';

export const ShowAdaptiveContext = createContext(null);

const ShowAdaptiveContextProvider = ({ children }) => {
	const [show, setShow] = useState(false);

	useEffect(() => {
		const callAPI = async () => {
			const res = await api.get({ endpoint: API_ROUTES.getShowAdaptive });
			setShow(res.data);
		};

		callAPI();
	}, []);

	return <ShowAdaptiveContext.Provider value={show}>{children}</ShowAdaptiveContext.Provider>;
};

export default ShowAdaptiveContextProvider;
