import { API_ROUTES } from '@constants/api';
import { api } from '@utils/api';
import Button from 'components/Button';
import UploadAudio from 'components/FilesUpload/UploadAudio';
import UploadFile from 'components/FilesUpload/UploadFile';
import UploadVideo from 'components/FilesUpload/UploadVideo';
import LearnerActivityDescription from 'components/LearnerActivityDescription';
import DownloadableFilesComponent from 'components/Reinforcements/DownloadableFilesComponent';
import Icons from 'public/assignment/Icons';
import React, { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';

type UserResponseType = 'audio' | 'video' | 'file' | 'text';

const OPTIONS: Array<{ name: string; type: UserResponseType }> = [
	{ name: 'Audio', type: 'audio' },
	{ name: 'Video', type: 'video' },
	{ name: 'File Upload', type: 'file' },
	{ name: 'Text Reply', type: 'text' },
];

interface IUserResponse {
	type: UserResponseType;
	response: any;
	setResponse: Dispatch<SetStateAction<any>>;
}

const UserResponse: FC<IUserResponse> = ({ type, response, setResponse }) => {
	switch (type) {
		case 'audio':
			return <UploadAudio file={response} setFile={setResponse} withURL={false} />;
		case 'video':
			return <UploadVideo file={response} setFile={setResponse} withURL={false} />;
		case 'file':
			return <UploadFile file={response} setFile={setResponse} />;
		case 'text':
			return (
				<textarea
					className="discussions-create-form-textarea"
					rows={10}
					value={response}
					onChange={(e) => setResponse(e.target.value)}
					maxLength={500}
				/>
			);
		default:
			return <></>;
	}
};

const AbstractAssignment = ({
	name,
	description,
	file,
	canBeCompleted,
	assignmentId,
	completion,
	completeActivity,
	cancel,
	readNotification,
}) => {
	const [selectedOption, setSelectedOption] = useState<UserResponseType>();
	const [response, setResponse] = useState();
	const [downloaded, setDownloaded] = useState(false);

	const handleOption = useCallback((type: UserResponseType) => {
		setResponse(null);
		setSelectedOption(type);
	}, []);

	const handleCompleteDownload = useCallback(() => {
		setDownloaded(true);
	}, [file]);

	const handleSubmit = useCallback(async () => {
		const payload = new FormData();

		payload.append('assignmentId', assignmentId);
		payload.append('completion', completion);
		payload.append('submittedAssignment[message]', 'test');

		if (selectedOption === 'text') {
			payload.append('submittedAssignment[textAnswer]', response);
		} else {
			payload.append('file', response);
		}

		await api.post_file({ endpoint: API_ROUTES.submitAssignment, payload });
		await readNotification();
		completeActivity();
	}, [response, selectedOption, completeActivity]);

	return (
		<div className="assignment">
			<LearnerActivityDescription title={name} text={description} />
			<DownloadableFilesComponent files={file ? [file] : []} onComplete={handleCompleteDownload} />
			<div className="option-container">
				<p className="title">Select an Option</p>
				<div className="options">
					{OPTIONS.map((item, index) => (
						<div key={index} className="option-card" onClick={() => handleOption(item.type)}>
							<Icons type={item.type} active={item.type === selectedOption} />
							<p style={{ color: item.type === selectedOption ? '#066BFB' : '#969696' }}>{item.name}</p>
						</div>
					))}
				</div>
			</div>
			<div className={selectedOption ? 'mt-4' : ''}>
				<p className="label" style={{ textTransform: 'capitalize' }}>
					{selectedOption ?? ''}
				</p>
				<UserResponse type={selectedOption} response={response} setResponse={setResponse} />
			</div>
			{!canBeCompleted ? (
				<div className="submit-btns-container">
					<div>
						<Button onClick={handleSubmit} disabled={!response}>
							Submit
						</Button>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default AbstractAssignment;
