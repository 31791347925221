import React from 'react';
import { AnswerFeedback, ReferenceModule } from 'types/DTOs/learnerActivityDTOs';

const Feedback = ({ feedback, referenceModule }: { feedback: AnswerFeedback; referenceModule?: ReferenceModule }) => {
	const goToReferenceModule = () => {
		localStorage.setItem('reference_module', referenceModule._id);
	};

	return (
		<>
			{feedback?.correctAnswerFeedback?.length || feedback?.feedback?.length ? (
				<div className="feedback">
					<p className="label">Feedback:&nbsp;</p>
					<div
						dangerouslySetInnerHTML={{
							__html: feedback?.correctAnswerFeedback || feedback?.feedback,
						}}
					/>
				</div>
			) : null}
			{!!referenceModule && feedback && !feedback?.isCorrectAnswer && (
				<div className="feedback reference">
					<p className="label">
						Refer to:&nbsp;
						<a
							href={`/dashboard/outline?courseId=${referenceModule.course}#${referenceModule._id}`}
							onClick={goToReferenceModule}
							target="_blank"
						>
							{referenceModule.name}
						</a>
					</p>
				</div>
			)}
		</>
	);
};

export default Feedback;
