import { parseTemplate } from 'url-template';

export const FE_ROUTES = {
	addLRActivity: '/course_management/learning_reinforcement/activity',
	adminPreviewActivity: parseTemplate(
		'/course_management/learning_reinforcement/admin-activity-previews/admin-{type}-preview'
	),
	adminLearningPath: parseTemplate('/course_management/lr_adaptive/{id}'),
	adminLearningPathCalendar: '/course_management/lr_adaptive/learning_path',
	assignmentSubmissions: '/course_management/submission',
	learnerReinforcementActivity: '/learner/reinforcements/learner-activity',
	learnerProficiencyTest: '/learner/dashboard/lr_adaptive',
	learnerReportsCourses: '/learner/reports/courses',
	learnerReportsCourseDetails: parseTemplate('/learner/reports/courses/{id}'),
} as const;
