import Button from 'components/Button';
import { useRouter } from 'next/router';
import React, { FC, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import SuccessContent from './SuccessContent';
import { ISuccessCompletionModal } from 'types/interfaces/modalInterfaces';

const SuccessCompletionModal: FC<ISuccessCompletionModal> = ({
	activityName,
	showModal,
	header,
	successMessage,
	showCompleted = 'Completed',
	children = null,
	showContinue = true,
	onContinue,
	close,
}) => {
	const router = useRouter();
	const handleContinue = useCallback(() => {
		if (onContinue) {
			onContinue();
		} else {
			router.back();
		}
	}, [onContinue]);

	return (
		<Modal
			show={showModal}
			backdrop="static"
			size="lg"
			dialogClassName="modal__success"
			keyboard={false}
			centered
			onHide={close ?? handleContinue}
		>
			<Modal.Header closeButton style={{ borderBottom: 0 }} />
			<Modal.Body className="d-flex justify-content-center text-center flex-column align-items-center p-4">
				{!!header && <p className="successful-modal-header">{header}</p>}
				<SuccessContent activityName={activityName} showCompleted={showCompleted} successMessage={successMessage} />
				{children}
				{showContinue && <Button onClick={handleContinue}>Continue Learning</Button>}
			</Modal.Body>
		</Modal>
	);
};

export default SuccessCompletionModal;
