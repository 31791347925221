export type DragDropAnswerLearnerDTO = {
	answer: string;
	feedback: string;
};

export type DragDropLearnerDTO = {
	_id: string;
	dragAndDropId: string;
	question: string;
	answers: DragDropAnswerLearnerDTO[];
};

export type AnswerFeedback = {
	correctAnswer: string;
	correctAnswerFeedback: string;
	wrongAnswerFeedback: string;
	feedback?: string;
	answer: string;
	isCorrectAnswer: boolean;
	_id: string;
};
export type AnswersFeedback = Array<AnswerFeedback>;

export type QuizFeedbackDTO = {
	_id: string;
	completion: string;
	activity: string;
	points: number;
	maxPoint: number;
	attemptNumber: number;
	status: number;
	submittedQuiz: Array<{
		sectionQuestionId: string;
		questionId: string;
		questionType: string;
		answers: AnswersFeedback;
		listCorrectAnswers: any[];
		listAnswers: any[];
	}>;
};

export type ReferenceModule = {
	_id: string;
	name: string;
	course: string;
};

export type Reinforcement = {
	_id: string;
	activity: { name: string; _id: string; type: string; reinforcementId?: string };
	notificationId?: string;
};

export type ReinforcementActivityExtraNotification = {
	reinforcementActivityName: string;
	activityID: string;
	reinforcementID: string;
	reinforcement: Reinforcement;
	later: LaterStatesEnum;
	description: string;
};

export enum LaterStatesEnum {
	SHOW = 'SHOW', //new notifications
	SHOW_ON_MODAL = 'SHOW_ON_MODAL',
	NO_SHOW = 'NO_SHOW', //no show on modal (take it later 2)
}
