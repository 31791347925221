export const ACTIVITY_STATUS_FILTER = {
	all: { label: 'Select All', value: -1, allowInFilters: true },
	notStarted: { label: 'Not Started', value: 0 , allowInFilters: false},
	inProgress: { label: 'In Progress', value: 1, allowInFilters: true },
	completed: { label: 'Completed', value: 2, allowInFilters: true },
	failed: { label: 'Not Passed', value: 3, allowInFilters: true },
	pendingReview:{ label: 'Pending Review', value: 4, allowInFilters: true },
} as const;

export const HEATMAP_VIEWS = {
	learning_objective: 'Learning Objective',
	competency: 'Competency',
};

export const LEADERBOARD_VIEWS = {
	overall: 'Overall',
	activity: 'Activity',
	learning: 'Learning Objective',
	competency: 'Competency',
} as const;
