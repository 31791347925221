import { FC, useCallback } from 'react';
import { AnswersFeedback, ReferenceModule } from 'types/DTOs/learnerActivityDTOs';
import FeedbackMark from './FeedbackMark';
import Feedback from './Feedback';

interface IQuizQuestion {
	id: string;
	type: string;
	questionData: any;
	index: number;
	feedback: AnswersFeedback;
	referenceModule?: ReferenceModule;
	showIndex?: boolean;
	showFeedback?: boolean;
	value?: any;
	onChange: (id: string, value: string, multiple?: boolean) => void;
}

const QuizCheckRadioOption = ({ answerObj, name, hasFeedback, feedbackAnswer, type, handleOptionChange, value }) => {
	let inputProps = {};
	if (hasFeedback || value !== null) {
		let checked = false;
		if (hasFeedback) {
			checked = feedbackAnswer === answerObj.answer;
		} else if (value !== null) {
			checked = Array.isArray(value) ? value.includes(answerObj.answer) : value === answerObj.answer;
		}
		inputProps = { checked };
	}

	return (
		<label key={answerObj.answer} className="option-label">
			<input
				type={['single', 'true_false', 'yes_no'].includes(type) ? 'radio' : 'checkbox'}
				name={name}
				value={answerObj.answer}
				onChange={handleOptionChange}
				{...inputProps}
			/>
			<span
				dangerouslySetInnerHTML={{
					__html: answerObj.label,
				}}
			/>
		</label>
	);
};

const QuizQuestion: FC<IQuizQuestion> = ({
	id,
	type,
	questionData,
	index,
	feedback,
	referenceModule,
	onChange,
	showIndex = true,
	showFeedback = true,
	value = null,
}) => {


	const handleOptionChange = (e) => {
		if (!feedback) {
			const { value } = e.target;
			onChange(id, value, type === 'multiple');
		}
	};

	const extractFeedback = useCallback(
		(answer?: string) => {
			if (feedback) {
				if (type === 'fill_in_the_blank') return feedback[0];
				const index = feedback.findIndex((item) => item.answer === answer);
				if (index >= 0) return feedback[index];
			}
			return null;
		},
		[feedback, questionData]
	);

	const questionIndex = showIndex ? `${index}.&nbsp;` : '';

	return (
		<div className="quiz-question">
			<p
				style={{ display: 'inline-flex' }}
				dangerouslySetInnerHTML={{
					__html: `${questionIndex}${questionData.question}`,
				}}
			/>

			{type === 'single' || type === 'multiple' || type === 'true_false' || type === 'yes_no' ? (
				<div className="options">
					{questionData.answers.map((answerObj, index) => {
						const individualFeedback = extractFeedback(answerObj.answer);
						return (
							<div key={`${answerObj.answer}-${index}`} className="option-container">
								<div className="feedback-marked-row">
									<FeedbackMark anyFeedback={!!feedback} individualFeedback={individualFeedback} />
									<QuizCheckRadioOption
										name={`question-${id}-answers`}
										type={type}
										answerObj={answerObj}
										hasFeedback={!!feedback}
										feedbackAnswer={individualFeedback?.answer}
										handleOptionChange={handleOptionChange}
										value={value}
									/>
								</div>
								{showFeedback && <Feedback feedback={individualFeedback} referenceModule={referenceModule} />}
							</div>
						);
					})}
				</div>
			) : ['fill_in_the_blank', 'fill_blank'].includes(type) ? (
				<>
					<div className="feedback-marked-row">
						<FeedbackMark anyFeedback={!!feedback} individualFeedback={extractFeedback()} />
						<input
							type="text"
							className="fill-blank"
							onChange={(e) => handleOptionChange(e)}
							value={value || questionData?.completionAnswer?.[0]?.answer}
							disabled={!!feedback}
						/>
					</div>
					{showFeedback && <Feedback feedback={extractFeedback()} referenceModule={referenceModule} />}
				</>
			) : null}
		</div>
	);
};

export default QuizQuestion;
