import SimpleModal from '../SimpleModal';
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import Button from 'components/Button';
import Step2ActivityComponent from './Step2ActivityComponent';
import { getReinforcementActivityField } from 'utils/helper';
import SuccessCompletionModal from './SuccessCompletionModal';
import { useLearnerReinforcementActivity } from 'utils/hooks/useLearnerReinforcementActivity';
import { isMobile } from 'react-device-detect';
import { useNavigation } from 'utils/hooks/useNavigation';
import { FE_ROUTES } from '@constants/feRoutes';
import { useShowLearnerCompletionButton } from 'utils/hooks/useShowLearnerCompletionButton';
import { useCompleteLearnerActivity } from 'utils/hooks/useCompleteLearnerActivity';
import { LEARNING_REINFORCEMENT_ACTIVITIES, LEARNING_REINFORCEMENT_GRADED_ACTIVITIES } from '@constants/activities';
import { LaterStatesEnum, ReinforcementActivityExtraNotification } from 'types/DTOs/learnerActivityDTOs';

const ActivityModal = ({
	activities,
	showModal,
	setClickedActivity,
	handleModalClose,
	showSpecificActivity,
	pendingActivityDescription,
}: {
	activities: ReinforcementActivityExtraNotification[];
	showModal: boolean;
	setClickedActivity: Dispatch<SetStateAction<any>>;
	handleModalClose: () => void;
	showSpecificActivity: null | {
		reinforcementID: string;
		activityID: string;
		activityName: string;
		notificationId: string;
	};
	pendingActivityDescription?: string;
}) => {
	const navigate = useNavigation();
	const [modalStep, setModalStep] = useState(0);
	const [canBeCompleted, setCanBeCompleted] = useState(false);
	const {
		state: { activityType, activityTypeObject, completionParams },
		getActivity,
		updateCompletionParams,
		reset,
		takeActivityLater,
	} = useLearnerReinforcementActivity();
	const showCompletionButton = useShowLearnerCompletionButton(activityType, canBeCompleted);
	const { success, completeActivity, reset: resetSuccess } = useCompleteLearnerActivity(activityType, completionParams);
	const [loading, setLoading] = useState(false);
	const [modalActivities, setModalActivities] = useState<ReinforcementActivityExtraNotification[]>([]);
	const modalStepRef = useRef(modalStep);

	useEffect(() => {
		modalStepRef.current = modalStep;
	}, [modalStep]);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	useEffect(() => {
		if (activities) {
			setModalActivities(activities);
		}
	}, [activities]);

	useEffect(() => {
		if (modalStepRef?.current > 1) return;
		if (showModal) {
			if (showSpecificActivity?.reinforcementID) {
				goToActivity(
					showSpecificActivity.reinforcementID,
					showSpecificActivity.activityID,
					showSpecificActivity.activityName,
					showSpecificActivity.notificationId
				);
			} else {
				setModalStep(1);
			}
		}
	}, [showSpecificActivity, showModal]);

	const takeActivitiesLater = (activities: ReinforcementActivityExtraNotification[]) => {
		const laterActivities = activities.map((item) => ({
			reinforcementID: item.reinforcement._id ?? item.reinforcement.activity.reinforcementId,
			activityID: item.reinforcement.activity._id,
		}));
		takeActivityLater(laterActivities);
	};

	const closeModal = useCallback(async () => {
		if (modalStep === 1) {
			takeActivitiesLater(modalActivities);
		}
		handleModalClose();
		setTimeout(() => {
			//to prevent displaying between steps
			setModalStep(1);
			reset();
			setCanBeCompleted(false);
			resetSuccess();
		}, 300);
	}, [modalStep, modalActivities]);

	const cancel = useCallback(() => {
		if (showSpecificActivity?.reinforcementID) {
			closeModal();
		} else {
			setModalStep(1);
			setCanBeCompleted(false);
		}
	}, [showSpecificActivity?.reinforcementID]);

	const goToActivity = useCallback(
		async (reinforcementID, activityID, activityName, notificationId) => {
			setClickedActivity(activityID);
			if (isMobile) {
				closeModal();
				navigate(FE_ROUTES.learnerReinforcementActivity, {
					reinforcementID,
					activityID,
					activityName,
					notificationId: notificationId,
				});
			} else {
				setLoading(true);
				await getActivity(reinforcementID, activityID, notificationId);
				setModalStep(2);
				setLoading(false);
			}
		},
		[isMobile]
	);

	const takeLater = useCallback(
		async (activity: ReinforcementActivityExtraNotification) => {
			await takeActivityLater([
				{
					reinforcementID: activity.reinforcement._id ?? activity.reinforcement.activity.reinforcementId,
					activityID: activity.reinforcement.activity._id,
				},
			]);
			setModalActivities((prev) =>
				prev.filter((item) => item.reinforcement.activity._id !== activity.reinforcement.activity._id)
			);
			if (modalActivities.length === 1) {
				closeModal();
			}
		},
		[modalActivities, takeActivityLater, closeModal]
	);

	const TakeButtons = useCallback(
		({ activity }: { activity: ReinforcementActivityExtraNotification }) => (
			<div className="button-container">
				<Button onClick={() => takeLater(activity)} type="secondary">
					Take it later
				</Button>
				<Button
					onClick={() => {
						goToActivity(
							activity.reinforcement._id ?? activity.reinforcement.activity.reinforcementId,
							activity.reinforcement.activity._id,
							activity.reinforcement.activity.name,
							activity.reinforcement.notificationId
						);
					}}
				>
					Take it now
				</Button>
			</div>
		),
		[takeLater, goToActivity]
	);

	const allNew = activities?.every((activity) => activity.later === LaterStatesEnum.SHOW);

	return success && showModal ? (
		<SuccessCompletionModal
			showModal={showModal}
			activityName={`${activityType === 'assignment' ? '' : 'Learning Reinforcement Activity - '}${
				activityTypeObject?.name
			}`}
			onContinue={closeModal}
			showCompleted={activityType === 'assignment' ? 'Thanks for submitting the assignment.' : 'Completed'}
		/>
	) : (
		<SimpleModal
			title={
				pendingActivityDescription && modalActivities?.length === 1
					? `Learning Reinforcement Activity - ${modalActivities[0]?.reinforcement.activity.name}`
					: getReinforcementActivityField(activityType, 'title') ?? 'Learning Reinforcement Activity'
			}
			show={showModal}
			handleClose={closeModal}
			loading={loading}
		>
			<div className="activity-modal">
				{modalStep === 1 &&
					(loading ? (
						<p className="description">Loading...</p>
					) : pendingActivityDescription && modalActivities?.length === 1 ? (
						<>
							<div className="description" dangerouslySetInnerHTML={{ __html: pendingActivityDescription }} />
							<TakeButtons activity={modalActivities[0]} />
						</>
					) : (
						<>
							<p className="description">{`Here are the ${
								allNew ? 'available' : 'pending'
							} learning reinforcement activities:`}</p>
							{modalActivities?.map((reinforcementActivity, index) => (
								<div key={reinforcementActivity.activityID} className="list-item-container">
									<p className="description">
										{index + 1}.&nbsp;
										<span className="activityType">
											{LEARNING_REINFORCEMENT_ACTIVITIES[reinforcementActivity.reinforcement.activity.type]?.title ??
												LEARNING_REINFORCEMENT_GRADED_ACTIVITIES[reinforcementActivity.reinforcement.activity.type]
													?.title}
											:{' '}
										</span>
										{reinforcementActivity.reinforcement.activity.name}
									</p>
									<TakeButtons activity={reinforcementActivity} />
								</div>
							))}
						</>
					))}
				{modalStep === 2 && activityTypeObject && (
					<div className="learner-activity">
						<Step2ActivityComponent
							completionID={activityTypeObject.reinforcementID}
							activityType={activityType}
							activityTypeObject={activityTypeObject}
							canBeCompleted={canBeCompleted}
							completionParams={completionParams}
							setCanBeCompleted={setCanBeCompleted}
							setCompletionParams={updateCompletionParams}
							completeActivity={completeActivity}
							cancel={cancel}
							closeModal={closeModal}
						/>

						{showCompletionButton ? (
							<div className="button-container completion-btn">
								<>
									<Button
										style={{
											width: '24rem',
										}}
										disabled={!canBeCompleted}
										onClick={completeActivity}
									>
										Complete & Continue
									</Button>
								</>
							</div>
						) : null}
					</div>
				)}
			</div>
		</SimpleModal>
	);
};

export default ActivityModal;
