import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import NotificationItem from './NotificationItem';
import generateTotalNotification from 'utils/calculate_notifications';
import { clearNotification, readNotification, unreadNotification } from 'services/api/InAppNotification';
import ReinforcementActivityNotificationItem from './ReinforcementActivityNotificationItem';
import ActivityModal from 'components/Reinforcements/ActivityModal';
import { LaterStatesEnum, ReinforcementActivityExtraNotification } from 'types/DTOs/learnerActivityDTOs';

const NotificationWrapper = ({ list, total, getList, isOpen, userFirstName, closeNotificationPanel }) => {
	const [currentType, setCurrentType] = useState('');
	const [clickedNotification, setClickedNotification] = useState(null);
	const [displayedReferenceModule, setDisplayedReferenceModule] = useState(false);

	const allUnreadReinforcements: ReinforcementActivityExtraNotification[] = useMemo(
		() =>
			list
				? list
						?.filter(
							(notification) =>
								notification?.extra?.reinforcement &&
								notification?.extra?.later !== LaterStatesEnum.NO_SHOW
						)
						?.map((notification) => ({
							...notification?.extra,
							description: notification.description,
							reinforcement: {
								...notification?.extra?.reinforcement,
								notificationId: notification?._id,
							},
						}))
				: [],
		[list]
	);

	const specificActivityParams = useMemo(
		() =>
			clickedNotification?.extra
				? {
						reinforcementID: clickedNotification.extra.reinforcementID,
						activityID: clickedNotification.extra.activityID,
						activityName: clickedNotification.extra.reinforcementActivityName,
						notificationId: clickedNotification._id,
				  }
				: null,
		[clickedNotification?.extra]
	);

	useEffect(() => {
		const referenceModule = localStorage.getItem('reference_module');
		if (referenceModule) {
			setDisplayedReferenceModule(true);
		}
		const timeout = setTimeout(() => {
			localStorage.removeItem('reference_module');
		}, 2000);

		return () => clearTimeout(timeout);
	}, []);

	useEffect(() => {
		return () => setDisplayedReferenceModule(false);
	}, [window.location.pathname]);

	const handleReadStatus = async ({ id, read }) => {
		if (read) {
			await readNotification({ notifications: [id] });
		} else {
			await unreadNotification({ id });
		}
	};

	const handleClearNotifications = async () => {
		const response = await clearNotification();

		if (!response.success) {
			alert(response.errors || 'Something Error');
		} else {
			await getList({});
		}
	};

	const handleFilterUnread = () => {
		setCurrentType('unread');
		getList({ type: 'unread' });
	};

	const clickReinforcementNotification = (notification) => {
		if (!notification.isRead) {
			handleReadStatus({ id: notification._id, read: true });
		}
		setDisplayedReferenceModule(false);
		setClickedNotification(notification);
		closeNotificationPanel();
	};

	const getReinforcementDescription = (notification) =>
		notification?.description
			? `Hi ${userFirstName}, your learning reinforcement activity - ${
					notification.extra?.reinforcementActivityName ?? notification.reinforcementActivityName
			  } is ${
					(notification.extra?.later ?? notification.later) === LaterStatesEnum.SHOW ? 'available' : 'pending'
			  }. Please complete it.`
			: null;

	const renderListItem = (data) => {
		let component = null;
		switch (data.code) {
			case 'reinforcement_activity':
				component = (
					<ReinforcementActivityNotificationItem
						key={data._id}
						{...data}
						description={getReinforcementDescription(data)}
						allUnreadReinforcements={allUnreadReinforcements}
						onClick={() => {
							clickReinforcementNotification(data);
						}}
						onChangeStatus={handleReadStatus}
					/>
				);
				break;
			default:
				component = <NotificationItem key={data._id} {...data} onChangeStatus={handleReadStatus} />;
				break;
		}
		return component;
	};

	const showActivityModal = (clickedNotification || allUnreadReinforcements?.length) && !displayedReferenceModule;

	const activityModalDescription = useMemo(() => {
		let notification = clickedNotification;
		if (allUnreadReinforcements?.length === 1 && allUnreadReinforcements[0].later === LaterStatesEnum.SHOW) {
			notification = allUnreadReinforcements[0];
		}
		return getReinforcementDescription(notification);
	}, [clickedNotification, allUnreadReinforcements]);

	return (
		<>
			<div className={`notification__wrapper ${isOpen ? 'd-inline-block' : 'd-none'}`}>
				<div className="notification__header">
					<Font>
						{generateTotalNotification(total)} Notification{total > 1 ? 's' : ''}
					</Font>

					{total ? (
						<div className="filter align-items-center w-auto">
							<div className="sort-wrapper w-auto h-fit" style={{ height: 'max-content' }}>
								<div className="sort p-0" style={{ height: 0 }}>
									<img role="button" src="/user-management/dot.svg" />
								</div>
								<div
									className="sort-dropdown mt-0 p-3 end-0 text-dark"
									style={{
										top: 10,
										left: 'unset',
										width: '30rem',
									}}
								>
									<div className="sort-dropdown__item m-0 p-3" onClick={handleFilterUnread}>
										Show only unread message
									</div>
									<div className="sort-dropdown__item m-0 p-3" onClick={handleClearNotifications}>
										Clear all notifications
									</div>
								</div>
							</div>
						</div>
					) : null}
				</div>

				{total ? <div className="d-flex flex-column">{list.map(renderListItem)}</div> : null}

				<Font weight="400" size="1.6rem" className="notification__footer">
					{total
						? `Displaying all ${!currentType ? '' : 'unread'} notifications for the last 30 days`
						: `You don't have any ${!currentType ? '' : 'unread'} notification`}
				</Font>
			</div>

			{showActivityModal ? (
				<ActivityModal
					showSpecificActivity={specificActivityParams}
					activities={allUnreadReinforcements}
					showModal={showActivityModal}
					setClickedActivity={setClickedNotification}
					handleModalClose={() => setClickedNotification(null)}
					pendingActivityDescription={activityModalDescription}
				/>
			) : null}
		</>
	);
};

export default NotificationWrapper;

export const Font = styled.div`
	font-family: 'Titillium Web';
	font-size: ${({ size }) => size || '1.8rem'};
	font-weight: ${({ weight }) => weight || 600};
	color: ${({ color }) => color || 'black'};
	background-color: transparent !important;
`;
