import React from 'react';
import { Font } from './NotificationWrapper';
import { ReinforcementActivityExtraNotification } from 'types/DTOs/learnerActivityDTOs';
import moment from 'moment';

type NotificationItemProps = {
	_id: string;
	picture: { path: string };
	title: string;
	description: string;
	sendAt: string;
	rawSendAt: string;
	isRead: boolean;
	extra: ReinforcementActivityExtraNotification;
	onClick: () => void;
	onChangeStatus: ({ id, read }: { id: string; read: boolean }) => void;
};

const ReinforcementActivityNotificationItem = ({
	_id,
	picture,
	title,
	description,
	sendAt,
	rawSendAt,
	isRead,
	extra,
	onClick,
	onChangeStatus,
}: NotificationItemProps) => {
	title = title.replace('{activity_name}', extra?.reinforcementActivityName);

	return (
		<div className={`d-flex notification__item gap-2 ${isRead ? 'read' : 'unread'} `} onClick={onClick}>
			<div className="profile-image">
				<img src={picture[0]?.path || '/navbar/avatar_dummy.svg'} className="w-100 h-100 object-fit-cover" />
			</div>
			<div className="ms-3 flex-grow-1">
				<Font size="1.6rem" className="mb-1">
					{title}
				</Font>
				<div
					className="mb-4 ck-content"
					dangerouslySetInnerHTML={{
						__html: description,
					}}
				/>

				<div className="d-flex justify-content-between">
					<Font size="1.4rem" weight="300">
						{moment(rawSendAt).format('DD/MM/YYYY')}
					</Font>
					<Font size="1.4rem" weight="300">
						{sendAt}
					</Font>
				</div>
			</div>
			<div className="filter align-items-center w-auto">
				<div className="sort-wrapper w-auto h-fit" style={{ height: 'max-content' }}>
					<div className="sort p-0" style={{ height: 0 }}>
						<img role="button" src="/user-management/dot.svg" />
					</div>
					<div
						className="sort-dropdown mt-0 p-3 end-0 text-dark"
						style={{
							top: 10,
							left: 'unset',
							width: '30rem',
						}}
					>
						<div
							className="sort-dropdown__item m-0 p-3"
							onClick={(e) => {
								e.stopPropagation();
								onChangeStatus({ id: _id, read: !isRead });
							}}
						>
							{isRead ? 'Mark as unread' : 'Mark as read'}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReinforcementActivityNotificationItem;
