import React, { FC } from 'react';
import { ISuccessModalContent } from 'types/interfaces/modalInterfaces';

const SuccessContent: FC<ISuccessModalContent> = ({
	activityName,
	successMessage = 'Successful',
	showCompleted = 'Completed',
	isFailed = false,
}) => (
	<div className="successful-completion-modal">
		{!isFailed ? (
			<>
				<img src="/global/success.gif" width={320} height={320} alt="Success" />
				<p className="success-p">{successMessage}</p>
			</>
		) : (
			<>
				<img src="/global/failed.gif" width={320} height={320} alt="Success" />
				<p className="failed-p">Failed</p>
			</>
		)}
		<p className="activity-name">{activityName}</p>
		{isFailed && <p className="failed-info">Unfortunately, you did not achieve the required score to pass the test.</p>}
		{showCompleted && <p className="completed-p">{showCompleted}</p>}
	</div>
);

export default SuccessContent;
