import React, { FC } from 'react';

interface IUploadFile {
	file: {
		name: string;
		path: string;
	};
	setFile: (file: any) => void;
}

const UploadFile: FC<IUploadFile> = ({ file, setFile }) => {
	const allow = {
		pdf: 'pdf',
		jpeg: 'jpeg',
		jpg: 'jpg',
		png: 'png',
		doc: 'doc',
		docx: 'docx',
		ppt: 'ppt',
		pptx: 'pptx',
		xls: 'xls',
		xlsx: 'xlsx',
	};

	const checkExtension = (name) => {
		let data = name.split('.');

		data = data[data.length - 1];
		if (allow[data]) return false;
		return true;
	};

	const handleFileChange = (e) => {
		if (e) {
			let file = e.target.files[0];

			if (checkExtension(file.name)) {
				alert('Forbidden file type!');
				return;
			}

			if (file.size > 100e6) {
				alert('File is too big');
				return;
			}

			setFile(file);
		}
	};

	const allowedFilesText = Object.values(allow).join(', ').replace('pdf', 'PDF');

	return (
		<div>
			<div className="discussions-create-form-file">
				<input
					type="file"
					onChange={handleFileChange}
					accept={Object.keys(allow)
						.map((i) => `.${i}`)
						.join(', ')}
				/>
				<div className="discussions-create-form-file-content">
					<img src="/discussions/upload.svg" alt="Upload" />
					<span className="discussions-create-form-file-text">
						Drop files to attach, or <span className="discussions-create-form-file-text-link">Browse</span>
						<br />( Max file size: 100MB )
					</span>
				</div>
			</div>
			<p className="discussions-create-form-file-hint">{`File Format: ${allowedFilesText}`}</p>
			{file ? (
				<div className="mt-4 gap-3 downloadable-card">
					<img src="/discussions/file.svg" alt="Doc Icon" draggable={false} />
					<div className="file-status d-flex flex-column gap-2">
						<div className="title">{file.name}</div>
					</div>
					<img
						onClick={() => setFile(null)}
						style={{ marginLeft: 'auto' }}
						role="button"
						src="/discussions/trash.svg"
						draggable={false}
					/>
				</div>
			) : null}
		</div>
	);
};

export default UploadFile;
