import { SpacedRepetitionOptionType } from 'types/learningReinforcementActivity';

export const LEARNING_REINFORCEMENT_ACTIVITIES = {
	presentation: {
		title: 'PPT',
		route: 'ppt',
		thumbnail: '/icon/icon_activity_presentation.svg',
	},
	video: {
		title: 'Video',
		route: 'video',
		thumbnail: '/icon/icon_activity_video.svg',
	},
	audio: {
		title: 'Audio',
		route: 'audio',
		thumbnail: '/icon/icon_activity_audio.svg',
	},
	download_file: {
		title: 'Downloadable Files',
		route: 'download',
		thumbnail: '/icon/icon_activity_download_file.svg',
	},
	flip_card: {
		title: 'Flip Cards',
		route: 'flip',
		thumbnail: '/icon/icon_activity_flip_card.svg',
	},
	lesson: {
		title: 'Lesson',
		route: 'lesson',
		thumbnail: '/icon/icon_activity_lesson.svg',
	},
	reading: {
		title: 'Reading',
		route: 'reading',
		thumbnail: '/icon/icon_activity_reading.svg',
	},
} as const;

export const LEARNING_REINFORCEMENT_GRADED_ACTIVITIES = {
	quiz: {
		title: 'Quiz',
		route: 'quiz',
		thumbnail: '/icon/icon_activity_quiz.svg',
	},
	assignment: {
		title: 'Assignment',
		route: 'assignment',
		thumbnail: '/icon/icon_activity_assignment.svg',
	},
	drag_drop: {
		title: 'Drag and Drop',
		route: 'drag',
		thumbnail: '/icon/icon_activity_drag_drop.svg',
	},
	scramble_sentences: {
		title: 'Scramble Sentence',
		route: 'scramble',
		thumbnail: '/icon/icon_activity_scramble_sentences.svg',
	},
	ordering: {
		title: 'Ordering',
		route: 'ordering',
		thumbnail: '/icon/icon_activity_ordering.svg',
	},
	sorting: {
		title: 'Sorting',
		route: null, //'sorting',
		thumbnail: '/icon/icon_activity_sorting.svg',
	},
	matching: {
		title: 'Matching',
		route: null, //'matching',
		thumbnail: '/icon/icon_activity_matching.svg',
	},
	real_time_assignment: {
		title: 'Real Time Assignment',
		route: null, //'real_time_assignment',
		thumbnail: '/icon/icon_activity_real_time_assignment.svg',
	},
} as const;

export const REPETITION_OPTIONS: Partial<Record<SpacedRepetitionOptionType['value'], SpacedRepetitionOptionType>> = {
	default: { label: 'Default', value: 'default' },
	custom: { label: 'Custom', value: 'custom' },
} as const;

export const REPETITION_EVALUATION_OPTIONS: Record<SpacedRepetitionOptionType['value'], SpacedRepetitionOptionType> = {
	default: { label: 'Default', value: 'default' },
	custom: { label: 'Custom', value: 'custom' },
	correct_answer: { label: 'Until learner gets correct answer', value: 'correct_answer' },
} as const;

export const FORBIDDEN_DUPLICATED_ACTIVITIES = {
	competency_framework: { key: 'competency_framework', title: 'Competency Framework' },
	lr_adaptive: { key: 'lr_adaptive', title: 'Learning Reinforcement' },
	lr_simple: { key: 'lr_simple', title: 'Learning Reinforcement' },
} as const;
